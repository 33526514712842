import React from "react";
import { Switch } from "@pankod/refine-antd";

import { useSettings } from "context/settings";

const EditMode = () => {
    const { settings, toggleEditable } = useSettings();
    return (
        <div style={{ width: "100%", position: "relative", padding: "15px 0px" }}>
            <Switch
                checkedChildren="Edit Mode"
                unCheckedChildren="View Mode"
                checked={settings.editable}
                onChange={toggleEditable}
                // center div
                style={{
                    background: settings.editable ? "linear-gradient(90deg,#31b7d9, #0170bd)" : "#00101f",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
                disabled={true}
            />
        </div>
    );
};

export default EditMode;
