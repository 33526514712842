import React from "react";

export const settingsContext = React.createContext<
    { settings: any; setSettings: React.Dispatch<React.SetStateAction<any>>; toggleEditable: () => void } | undefined
>(undefined);

export const SettingsProvider: React.FC<{ children: JSX.Element[] | JSX.Element }> = ({ children }) => {
    const [settings, setSettings] = React.useState<any>({ editable: false });
    const toggleEditable = () => {
        setSettings((prev: any) => ({ ...prev, editable: !prev.editable }));
    };
    return <settingsContext.Provider value={{ settings, setSettings, toggleEditable }}>{children}</settingsContext.Provider>;
};

export const useSettings = () => {
    const settings = React.useContext(settingsContext);
    if (settings === undefined) {
        throw new Error("usesettings must be used within a settingsProvider");
    }
    return settings;
};
