import { IResourceComponentsProps } from "@pankod/refine-core";
import { useState, useRef, useEffect } from "react";

import {
    List,
    Table,
    Form,
    Space,
    Button,
    CreateButton,
    TextField,
    useEditableTable,
    notification,
    Input,
    SaveButton,
    Switch,
} from "@pankod/refine-antd";

import { IOrganization } from "interfaces";
import axios from "axios";
import { useSettings } from "context/settings";
import env from "config/site.config";
import type { ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import React from "react";
import CreateNewOrganization from "./new-organization";
import EditableProfileRow from "./profile";
import { TextareaAutosize } from "@pankod/refine-mui";

const TABLE_COLUMNS = [
    {
        dataIndex: "organization_id",
        title: "ID",
    },
    {
        dataIndex: "name",
        title: "Name",
    },
    {
        dataIndex: "website",
        title: "Website",
    },
    {
        dataIndex: "profiles",
        title: "Profiles",
    },
    {
        dataIndex: "seats",
        title: "Seats",
    },
    {
        dataIndex: "active",
        title: "Active",
        boolean: true,
    },
    {
        dataIndex: "finished_checklist",
        title: "Checklist Completed",
        boolean: true,
    },
];

export const OrganizationsList: React.FC<IResourceComponentsProps> = () => {
    const [checkedRows, setCheckedRows] = useState<{ [key: string]: boolean }>({});
    const [totalCount, setTotalCount] = useState<number>(0);

    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInputRef = useRef<InputRef>(null);

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => {
        console.log(selectedKeys[0]);
        confirm();
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };

    const generateInviteLink = async (organizationId: string) => {
        try {
            const res = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/organizations/${organizationId}/invite`,
                {},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            return res.data;
        } catch (error) {
            console.log(error);
            api.error({
                message: "Error",
                description: "Failed to generate invite link",
            });
        }
    };

    const createWorkspace = async (organizationId: string, name: string) => {
        try {
            const res = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/organizations/${organizationId}`,
                {
                    name,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            return res.data;
        } catch (error) {
            console.log(error);
            api.error({
                message: "Error",
                description: "Failed to create workspace",
            });
        }
    };

    const updateOrganization = async (
        organizationId: string,
        data: {
            active?: boolean;
            seats?: number;
            finished_checklist?: boolean;
        }
    ) => {
        try {
            const res = await axios.patch(`${env.REACT_APP_BACKEND_URL}/api/v1/organizations/${organizationId}`, data, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });

            return res.data;
        } catch (error) {
            console.log(error);
            api.error({
                message: "Error",
                description: "Failed to update organization",
            });
        }
    };

    const getTotalCount = async () => {
        axios
            // search with a matching search
            .get(`${env.REACT_APP_BACKEND_URL}/api/v1/organizations/count/`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                console.log("count", res.data.count);

                setTotalCount(res.data.count);
            });
    };

    useEffect(() => {
        getTotalCount();
    }, []);

    const getColumnSearchProps = (dataIndex: any): ColumnType<IOrganization> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            return (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInputRef}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{ marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                close();
                            }}
                        >
                            close
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered: boolean) => {
            return <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInputRef.current?.input?.select(), 100);
            }
        },
        render: (text) => (searchedColumn === dataIndex ? <span style={{ color: "#1890ff" }}>{text}</span> : text),
    });

    const [api, contextHolder] = notification.useNotification();

    const {
        tableProps,
        formProps,
        isEditing,
        setId: setEditId,
        saveButtonProps,
        cancelButtonProps,
        editButtonProps,
    } = useEditableTable<IOrganization>({ resource: `organizations`, id: "organization_id", initialPageSize: 50 });

    return (
        <List>
            <div>
                 
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        top: "0",
                    }}
                >
                    <div style={{ fontSize: "25px", fontWeight: "bold" }}>Organizations</div>
                </div>
                <Space>
                    <CreateNewOrganization />
                </Space>
                {contextHolder}
                <Form {...formProps} initialValues={[]}>
                    <Table
                        {...tableProps}
                        // on render check for blacklisted
                        sticky={true}
                        scroll={{ x: TABLE_COLUMNS.length * 150 }}
                        expandable={{
                            rowExpandable: () => true,
                            expandedRowRender: (record) => (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "10px",
                                    }}
                                >
                                    <h3>Profiles</h3>
                                    {record.profiles?.map((profile) => (
                                        <EditableProfileRow
                                            key={profile.profile_id}
                                            profile={profile}
                                            organizationId={record.organization_id}
                                            users={record.users}
                                        />
                                    ))}
                                    <EditableProfileRow organizationId={record.organization_id} users={record.users} />
                                </div>
                            ),

                            expandRowByClick: true,
                        }}
                        rowClassName={(record) => (checkedRows[record.organization_id] ? "selected-color" : "")}
                        pagination={{
                            total: totalCount,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "20", "50", "100", "500", "1000", "5000"],
                            defaultPageSize: 50,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        // rowSelection={{
                        //     selectedRowKeys: Object.keys(checkedRows).filter((key) => checkedRows[key]),
                        //     onChange: (selectedRowKeys) => {
                        //         const newCheckedRows: any = {};
                        //         selectedRowKeys.forEach((key) => {
                        //             newCheckedRows[key] = true;
                        //         });
                        //         setCheckedRows(newCheckedRows);
                        //     },
                        // }}
                        rowKey={(record) => record.organization_id}
                        onRow={(record) => ({
                            // eslint-disable-next-line
                            onClick: (event: any) => {
                                if (event.target.tagName === "TD" || event.target.parentNode.tagName === "TD") {
                                    setEditId && setEditId(record.organization_id);
                                }
                            },
                        })}
                    >
                        {TABLE_COLUMNS.map((column) => {
                            return (
                                <Table.Column<IOrganization>
                                    // insert getSearchProps here
                                    {...getColumnSearchProps(column.dataIndex)}
                                    key={column.dataIndex}
                                    dataIndex={column.dataIndex}
                                    title={column.title}
                                    width={"auto"}
                                    sorter={(a, b) => {
                                        if (column.dataIndex === "profiles") {
                                            return a.profiles.length - b.profiles.length;
                                        }
                                        return a[column.dataIndex as keyof IOrganization]
                                            ?.toString()
                                            .localeCompare(b[column.dataIndex as keyof IOrganization]?.toString());
                                    }}
                                    sortDirections={["ascend", "descend"]}
                                    defaultSortOrder={column.dataIndex === "name" ? "descend" : undefined}
                                    render={(value, record) => {
                                        if (column.dataIndex === "linkedin_automation_id" && isEditing(record.organization_id) && !value) {
                                            return (
                                                <Form.Item name={column.dataIndex} style={{ margin: 0 }} initialValue={value}>
                                                    <TextareaAutosize
                                                        style={{
                                                            background: "#FFFFFF",
                                                            border: "1px solid #7A7A7A",
                                                            borderRadius: "6px",
                                                            width: "100%",
                                                            whiteSpace: "pre-wrap",
                                                            resize: "none",
                                                            overflow: "hidden",
                                                        }}
                                                    />
                                                </Form.Item>
                                            );
                                        }
                                        switch (column.dataIndex) {
                                            case "profiles":
                                                return <TextField value={record?.profiles?.length} />;
                                            case "active":
                                            case "finished_checklist":
                                                return (
                                                    <Switch
                                                        defaultChecked={record[column.dataIndex]}
                                                        onChange={async (checked) => {
                                                            const res = await updateOrganization(record.organization_id, {
                                                                [column.dataIndex]: checked,
                                                            });
                                                            if (!res) {
                                                                return;
                                                            }
                                                            api.success({
                                                                message: "Success",
                                                                description: `Organization updated`,
                                                            });
                                                        }}
                                                    />
                                                );
                                            case "seats":
                                                return (
                                                    <Input
                                                        defaultValue={value}
                                                        type="number"
                                                        onChange={async (e) => {
                                                            const res = await updateOrganization(record.organization_id, {
                                                                seats: e.target.valueAsNumber,
                                                            });
                                                            if (!res) {
                                                                return;
                                                            }
                                                            api.success({
                                                                message: "Success",
                                                                description: `Organization updated`,
                                                            });
                                                        }}
                                                    />
                                                );
                                            default:
                                                return <TextField value={typeof value === "object" ? JSON.stringify(value) : value} />;
                                        }
                                    }}
                                />
                            );
                        })}

                        {
                            <Table.Column<IOrganization>
                                title="Actions"
                                dataIndex="actions"
                                render={(_, record) => {
                                    return (
                                        <Space direction="vertical">
                                            <CreateButton
                                                onClick={async () => {
                                                    const res = await generateInviteLink(record.organization_id);
                                                    if (!res) {
                                                        return;
                                                    }
                                                    api.success({
                                                        message: "Success",
                                                        description: `Invite link: ${res.link}`,
                                                        duration: 0,
                                                        btn: (
                                                            <Button
                                                                type="primary"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(res.link);
                                                                    api.destroy();
                                                                    api.success({
                                                                        message: "Success",
                                                                        description: "Copied to clipboard",
                                                                    });
                                                                }}
                                                            >
                                                                Copy
                                                            </Button>
                                                        ),
                                                    });
                                                }}
                                                size="small"
                                            >
                                                Generate Invite Link
                                            </CreateButton>

                                            {!record.linkedin_automation_id && (
                                                <SaveButton
                                                    onClick={async () => {
                                                        const res = await createWorkspace(record.organization_id, record.name);
                                                        if (!res) {
                                                            return;
                                                        }
                                                        api.success({
                                                            message: "Success",
                                                            description: `
                                                                Workspace created with name: ${res.name}
                                                                and id: ${res.linkedin_automation_id}
                                                            `,
                                                        });
                                                    }}
                                                    size="small"
                                                >
                                                    Create Workspace
                                                </SaveButton>
                                            )}
                                        </Space>
                                    );
                                }}
                            />
                        }
                    </Table>
                </Form>
            </div>
        </List>
    );
};
